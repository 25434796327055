
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmTextCopy from '@/components/shared/TmTextCopy.vue'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
    TmFormLine,
    TmTextCopy,
  },
  setup() {
    const url = 'https://my.textmagic.com/webhook/byoc/sinch/sms/incoming'
    return {
      url,
    }
  },
})
